import React, { useEffect } from 'react'
import { DownIcon } from '../svg/icons'
import { getNumber } from '../store'
import { connect } from 'react-redux'
const JobSearch = ({ getClick, hreff, number, name, description, getCurNumber }) => {

    return (
        <div className='project_listings_current'>
            <div className='square'>
            </div>
            <div className='square-2'>
            </div>
            <div className='project_listings_current-name'>
                <h1>{name}</h1>
            </div>
            <div className='project_listings_current-description'>
                <h4>{description}</h4>

            </div>
            <a href={`#${hreff}`}>
                <div className='downBtn' onClick={() => {
                    getCurNumber(number)
                    getClick()


                }}>
                    <DownIcon size='20' color='gray' />
                </div>
            </a>

        </div>
    )
}

const dispatchState = (dispatch) => {
    return { getCurNumber: (n) => dispatch(getNumber(n)) }
}
export default connect(null, dispatchState)(JobSearch) 