
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components'
import { Provider } from 'react-redux'
import store from './store'
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider >,
    document.getElementById('root') // make sure this is the same as the id of the div in your index.html
);