import React, { Fragment } from 'react'
import { UpIcon } from '../svg/icons'
import { connect } from 'react-redux'
import { clearThunk } from '../store'
import Gallery from './carosel'
import { ProjectDes } from '../util'


const ProjectDescription = ({ getClick, images, clear, num }) => {
    const Description = ProjectDes[num]
    return (
        <Fragment>
            <div className='myCarosel'>
                <Gallery images={images} />
            </div>
            <div className='project-description'>
                <Description />
            </div>
            <div className='upBtn' onClick={() => {
                getClick()
                clear()
            }}>
                <UpIcon />
            </div>
        </Fragment>
    )
}
const dispatcState = (dispatch) => {
    return { clear: () => dispatch(clearThunk()) }
}
export default connect(null, dispatcState)(ProjectDescription)

