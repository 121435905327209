import React, { useState, Fragment, useEffect } from 'react'
import { JobSection, Footer, Info, Header, ProjectDescription } from './index'
import { descriptionsOptions } from '../util'
import images from '../util'
import { connect } from 'react-redux'
import AOS from 'aos'

const App = ({ number }) => {
    const [buttonClick, setButton] = useState(false);
    const [update, setUpdate] = useState()

    const getClick = () => {
        setButton(!buttonClick)
    }

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
        setUpdate(number)
    }, [number])
    return (
        <div className='main_page'>
            <section className='header sections' >
                <Header />
            </section>
            <section className='info sections'>
                <Info />
            </section>
            <section className='section-header'>
                <h1>PROJECTS</h1>
            </section>

            <section className='project sections'>
                <div id='hidden' style={{ height: buttonClick ? '100%' : '0' }} className='project_hidden'>
                    <div
                        className='project_hidden-content'
                        style={{
                            opacity: buttonClick ? '1' : '0',
                            visibility: buttonClick ? ' visible' : 'hidden',
                            transitionDelay: buttonClick ? '0.2s' : '0.1s'
                        }}>
                        {

                            update || update === 0 ? <ProjectDescription getClick={getClick} images={images[update]} num={update} /> : ''
                        }
                    </div>
                </div>

                <div className='project_listings' >

                    <JobSection description={descriptionsOptions[3]}
                        number={3} getClick={getClick}
                        hreff='hidden'
                        name='PayDay' />

                    <hr />
                    <JobSection description={descriptionsOptions[1]}
                        number={1}
                        getClick={getClick}

                        hreff='hidden'
                        name='Whiteboard' />
                    <hr />
                    <JobSection description={descriptionsOptions[2]}
                        number={2} getClick={getClick}
                        hreff='hidden'
                        name='Happy Resident' />

                    <hr />
                    <JobSection description={descriptionsOptions[0]}
                        number={0} getClick={getClick}
                        hreff='hidden'
                        name='Light Text' />


                </div>
            </section>



            <section className='footer sections'>
                <Footer />
            </section>

        </div>
    )
}
const mapState = (state) => {
    return { number: state }
}
export default connect(mapState)(App) 