import React from 'react'
import { Icons } from './index'
const Info = () => {

    return (
        <div id='see_all' className='info-box'>
            <div className='info-box-skill'>
                <h1>SKILLS</h1>
            </div>

            <div className='info-box--icons'>
                <Icons />
            </div>

        </div>
    )
}

export default Info  