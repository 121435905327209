import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { connect } from 'react-redux'

const Gallery = ({ images }) => {

    const handleOnDragStart = (e) => e.preventDefault()
    return (

        <AliceCarousel mouseTrackingEnabled buttonsDisabled fadeOutAnimation
        >
            <div className='myCarosel-images' >
                <img src={images[0]} onDragStart={handleOnDragStart} className="yours-custom-class" />
            </div>
            <div className='myCarosel-images'>
                <img src={images[1]} onDragStart={handleOnDragStart} className="yours-custom-class" />
            </div>
            <div className='myCarosel-images' >
                <img src={images[2]} onDragStart={handleOnDragStart} className="yours-custom-class" />
            </div>

        </AliceCarousel>

    )
}

const mapState = (store) => {
    return { number: store }
}
export default connect(mapState)(Gallery)                