import React, { useState, useRef } from 'react'
import { NodeJS } from '../svg/icons'

const Header = () => {

    const [hoverd, setHoverd] = useState(false)

    return (
        <div className='header_container'>
            <div className='header_container-info'>
                <div
                    className='iconName'
                    style={{
                        height: hoverd ? '61.5%' : '70%',
                        borderBottom: hoverd ? '25px solid black' : '',
                    }}

                >
                </div>

                <div className='header_container-info--text' >
                    <div className='circle'>

                    </div>
                    <div className='header_container-info--text_header' >
                        <div className='header_container-info--text_header-image'  >
                            <img src='images/profile.jpg' />
                        </div>
                        <h1 className='header_container-info--text_header-name'>TAULANT VOKSHI</h1>
                        <h4 className='header_container-info--text_header-portfolio' >Software developer</h4>
                    </div>

                    <div onMouseEnter={() => {
                        setHoverd(!hoverd)
                    }} onMouseLeave={() => {
                        setHoverd(!hoverd)
                    }}

                        className='header_container-info--text_description'>
                        <a href="#see_all" className='header_container-info--text_description-text'>
                            <h4 className='header_container-info--text_description-text' >See what i can do!</h4>
                        </a>
                    </div>
                </div>

            </div>

            <div className='header_container-profile'>
                <div className='header_container-profile--image'>
                    <img src='images/profile.jpg' />
                </div>

            </div>


        </div>
    )
}

export default Header