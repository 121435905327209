import React, { Fragment } from 'react'
import {
    NodeJS,
    Git,
    Express,
    AuthO,
    Python,
    SocketIo,
    Sass,
    ReactJsx,
    HTML5,
    Javascript,
    Css,
    Redux,
    PostgreSql,
    Sequelize,
    WebPack,
    AWS,
    Django

} from '../svg/icons'


const Icons = () => {
    return (
        <Fragment>



            <div className='icons'>
                <Javascript width='40px' height='40px' />
                <h4 className='icons-innerText'>javascript</h4>
            </div>
            <div className='icons'>
                <Python />
                <h4 className='icons-innerText'>python</h4>
            </div>

            <div className='icons'>
                <NodeJS />
                <h4 className='icons-innerText'>nodeJS</h4>
            </div >

            <div className='icons'>
                <Django size='50px' />
                <h4 className='icons-innerText'>framework</h4>
            </div>

            <div className='icons'>
                <PostgreSql />
                <h4 className='icons-innerText'>postgreSql</h4>
            </div>


            <div className='icons '>
                <AWS />
                <h4 className='icons-innerText'>AWS</h4>
            </div>



            <div className='icons'>
                <Express />
                <h4 className='icons-innerText'>express</h4>
            </div>


            <div className='icons'>
                <AuthO />
                <h4 className='icons-innerText'>AuthO</h4>
            </div>


            <div className='icons'>
                <Git />
                <h4 className='icons-innerText'>git</h4>
            </div>


            <div className='icons'>
                <SocketIo />
                <h4 className='icons-innerText'>socket.io</h4>
            </div>



            <div className='icons'>
                <ReactJsx />
                <h4 className='icons-innerText'>react</h4>
            </div>

            <div className='icons'>
                <Redux width='40px' height='40px' />
                <h4 className='icons-innerText'>redux</h4>
            </div>


            <div className='icons'>
                <Sequelize width='40px' height='40px' />
                <h4 className='icons-innerText'>sequelize ORM</h4>
            </div>


            <div className='icons'>
                <WebPack />
                <h4 className='icons-innerText'>webpack</h4>
            </div>


            <div className='icons'>
                <Sass />
                <h4 className='icons-innerText'>sass</h4>
            </div>



            <div className='icons'>
                <Css width='40px' height='40px' />
                <h4 className='icons-innerText'>css</h4>
            </div>

            <div className='icons '>
                <HTML5 width='40px' height='40px' />
                <h4 className='icons-innerText'>html</h4>
            </div>



        </Fragment>
    )
}


export default Icons