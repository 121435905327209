import React from 'react'

const Footer = () => {
    return (
        <div className='footer_container'>
            <ul className='footer_container-list'>
                <a href='https://www.linkedin.com/in/taulant-vokshi/'> <li>LinkedIn</li></a>
                <a href='https://github.com/Taulantvokshi'> <li>GitHub</li></a>
                <a href='images/resume.pdf' download><li>Resume</li></a>
            </ul>

        </div>
    )
}

export default Footer
