import React from 'react'


export default [
    ['images/lighttext-main.png', 'images/lighttext-dash.png', 'images/lighttext-charts.png']
    ,
    ['images/whiteboard.png', 'images/whiteboard-event.png', 'images/whiteboard-front.png'],

    ['images/happyresident-settings.png', 'images/happyresident-account.png', 'images/happyresident-assist.png'],
    ['images/pos-main.png', 'images/pos-orders.png', 'images/pos-employees.png']
]

export const descriptionsOptions = [
    'A text message payment system',
    'Whiteboard coding Hub',
    'Building management system',
    'POS System'
]





const LightText = () => {
    return (<p>LightText (LT) is a lightning wallet that allows you to send and receive bitcoin payments over the lightning network. <br /> LT is an SMS client aimed to provide
    access to crypto infrastructure for individuals without smartphones.<br />
        Grab your favorite Nokia phone, sign up, fund your account with bitcoin and begin paying friends and lightning invoices.<br />
        <br />
        Website : <a style={{ textDecoration: 'none', color: '#385170' }} href='http://light-text.herokuapp.com/' >light-text.herokuapp.com</a> <br />
        Youtube : <a style={{ textDecoration: 'none', color: '#385170' }} href='https://youtu.be/SxjsJ4FDSzI'>https://youtu.be/SxjsJ4FDSzI</a></p>
    )
}



const Happyresident = () => {
    return (<p>
        Happyresident is a building management system that gives both the building owners and the tenants an easy way to manage,    <br /> organize and efficiently respond to their requests.<br />
        By implementing an easy to use dashboard, residents have access to a variety of services, <br />like reporting maintenance problems, paying the rent, check their rent status throughout time, etc.


    <br />
        <br />
        Technologies used: <br />
        <br />
        Backend: NodeJS, Express, Sequelize, Postgres AWS and Stripe <br />
        Frontend: Javascript, React, Redux, Sass, Html   <br />
        <br />
        Website : <a style={{ textDecoration: 'none', color: '#385170' }} href='https://happyresident.herokuapp.com/' >happyresident.herokuapp.com</a> <br />
    </p>
    )
}




const Whiteboard = () => {
    return (<p>
        Whiteboard is a social platform web application that helps new developers the ability to practice and simulate coding interviews.<br />
        A developer can find, search or create a new event  of their choice and join groups of people that share the same interest.
    <br />
        <br />
        Technologies used: <br />
        <br />
        Backend: NodeJS, Express, Sequelize, Postgres and AWS <br />
        Frontend: Javascript, React, Redux, Sass, Html   <br />
        <br />
        Website : <a style={{ textDecoration: 'none', color: '#385170' }} href='https://whiteboard-practice.herokuapp.com/' >whiteboard-practice.herokuapp.com</a> <br />
    </p>
    )
}

const PayDay = () => {
    return (<p>
        PayDay is a modern POS system, that monitors all of your store orders, transactions, and employees. <br />
        By accessing and using a very simple jet sophisticated UI, employees, and the managers can conduct their order of business with ease.

    <br />
        <br />
        Technologies used: <br />
        <br />
        Backend: NodeJS, Express, Sequelize, Postgres, AWS <br />
        Frontend: Javascript, React, Redux, Sass, Html   <br />
        <br />
        Website : <a style={{ textDecoration: 'none', color: '#385170' }} href='https://pos-systems.herokuapp.com/' >pos-systems.herokuapp.com</a> <br />
    </p>
    )
}


export const ProjectDes = [
    LightText,
    Whiteboard,
    Happyresident,
    PayDay
]



