import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'


const n = 0


export const getNumber = (n) => dispatch => {
    return dispatch({
        type: 'NUM',
        number: n
    })
}

export const clearThunk = () => dispatch => {
    return dispatch({
        type: 'CLEAR',
        number: ''
    })
}




const reducer = (state = n, action) => {
    if (action.type === 'NUM') {
        state = action.number
        return state
    }
    if (action.type === 'CLEAR') {
        state = undefined
        return state
    }
}


const midelware = applyMiddleware(thunkMiddleware)
const store = createStore(reducer, midelware)
export default store

