import React, { useState, useEffect, useRef } from 'react'


const MyCarosel = ({ images }) => {
    const [counter, setCounter] = useState(0)

    const myRef = useRef()
    const setEffect = (n, ref) => {
        ref.current.style.opacity = '0'
        setTimeout(() => {
            setCounter(n)

        }, 200)
        setTimeout(() => {
            return ref.current.style.opacity = '1'
        }, 400)
    }
    return (
        <div className='myCarosel'>
            <div ref={myRef} className='myCarosel-images' >
                <img src={images[counter]} />
            </div>
            <div className='myCarosel-clicks'>
                <div onClick={() => {
                    setEffect(0, myRef)


                }}>O</div>
                <div onClick={() => {
                    setEffect(1, myRef)

                }}>O</div>
                <div onClick={() => {
                    setEffect(2, myRef)




                }}>O</div>
            </div>
        </div>
    )
}

export default MyCarosel